<template>
    <div>
        <div class="replyPanel" v-if='getZanList.length || replyAllList.length'>
            <div class="header underline" v-if='getZanList.length'>
                <div class="f_flex f_a_c f_w">
          <span class="f_flex f_a_c">
            <img class="icon_img" src="../../assets/icon_zan.png" alt="">
          </span>
                    <span v-for='(item, index) in getZanList'
                          :key='index'>{{ item.userName }}{{ index === getZanList.length - 1 ? '' : '，' }}</span>
                </div>
            </div>
            
            <div v-if='replyAllList.length'>
                <div class="replyItem" v-for='(item, index) in replyAllList' :key='index'>
                    <div class="contentWp" :class='[index==replyAllList.length-1 ? "" : "underline"]'
                         @click='reply(item)'>
                        <div class="f_flex f_a_e f_j_sb" style="margin-bottom: 2px">
                            <div class="name">{{ item.fromUserName }}</div>
                            <div class="date">{{ item.publicTime }}</div>
                        </div>
                        <div class="content">
                            <span v-if='item.toUserName'>回复<span
                                style="color: #9B9B9B">{{ item.toUserName }}</span>: </span>
                            <div v-html="item.content"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="f_flex f_a_c f_j_sb" style="margin-top: 12px; width: 100%; box-sizing: border-box">
            <div class="pos_r f_g1">
<!--                @input="handleOnListenerInput($event)"-->
        <textarea
            type="text"
            ref='inputPanel'
            @keydown.enter="submitRemarkFn"
            @keyup="inputKeyEvent"
            @blur="blurRemarkFn"
            v-model='remark'
            class="remarkPanel"
            :style="{height: wordHeight+'px', borderRadius: wordHeight>50 ? '10px' : '30px'}"
            :placeholder="placeholder">
        </textarea>
                <i v-if='remark' @click='submitRemarkFn' class="iconfont icon-send-f icon_send theme_c"></i>
            </div>
            
            <div class="f_flex f_a_c">

                <div @click='gotoChoose' v-if="false">
                    <transition name='bounce' mode='out-in'>
                        
                        <img key='n' class="iconInfo" src="../../assets/at-sign.png" alt="">
                    </transition>
                </div>

                <div @click='zanFn'>
                    <transition name='bounce' mode='out-in'>
                        <img key='y' v-if='isZan' class="iconInfo" src="../../assets/icon_zan.png" alt="">
                        <img key='n' v-else class="iconInfo" src="../../assets/icon_zan_e.png" alt="">
                    </transition>
                </div>
                <!-- <img @click='shareFn' class="iconInfo" src="../../assets/icon_share_e.png" alt=""> -->
                <div @click="startFn" v-if='showStar'>
                    <transition name='bounce' mode="out-in">
                        <img key='y' v-if='isStar' class="iconInfo" src="../../assets/icon_star.png" alt="">
                        <img key='n' v-else class="iconInfo" src="../../assets/icon_star_e.png" alt="">
                    </transition>
                    <!-- <transition name='bounce_sm'>
                      <img v-if='!isStar' class="iconInfo" src="../../assets/icon_star_e.png" alt="">
                    </transition> -->
                </div>
            </div>
        </div>
         <van-popup
            get-container="#app"
            v-model="showChoose"
            position="bottom"
            :style="{ height: '90%' }"
            >
            <choose  ref="replyChoose" :groupId="groupId" :postsId="postsId" fromPage="reply" @closeChoose="closeChoosePopup"/>
        </van-popup>
    </div>

   
</template>

<script>
import postApi from '@/api/post'
import {mapGetters, mapActions} from 'vuex'
import choose from './choose.vue'

export default {
    data: () => ({
        remark: '',
        placeholder: '说点什么...',
        replyId: 0,
        replyItem: null,
        selfReplyList: [],
        arr: [],
        remindUserIdList:[],
        showChoose: false,
        activatedFlag: false,
        lastInput: ''
    }),
    props: {
        isZan: Boolean,
        isStar: Boolean,
        postsId: {
            type: Number,
            defalut: 0
        },
        groupId: {
            type: Number,
            defalut:0
        },
        zanList: {
            type: Array,
            default: () => ([])
        },
        replyList: {
            type: Array,
            default: () => ([])
        },
        showStar: {
            type: Boolean,
            default: true
        }
    },
    components: {
        
        choose,
        // 'van-image': vanImage
    },

    watch: {
        get_postAnchorListMap: function() {
            console.log("this.get_postAnchorPage", this.get_postAnchorPage)
            console.log("this.infoComponent.postsId    ----------------", this.postsId)
            let postAnchorListMap = this.get_postAnchorListMap
                if (this.activatedFlag && this.get_postAnchorPage == 'reply' && postAnchorListMap && postAnchorListMap.get) {
                    
                    let anchorList = postAnchorListMap.get(this.postsId)
                    console.log("anchorList", anchorList)
                    if (anchorList && anchorList.length > 0) {
                        const inputContent = this.$refs.inputPanel
                        if (inputContent) {
                            inputContent.focus ()
                        }
                        anchorList.forEach ((item, index) => {
                            if (index == 0) {
                                this.remark += item.userName
                            } else {
                                this.remark += "@" + item.userName
                            }
                            
                            this.remindUserIdList.push (item.userId) 
                        })
                        console.log("this.remark", this.remark)
                    }
                    
                }
        },
        
    },
    
    computed: {
        ...mapGetters ([
        'get_postAnchorListMap',
        'get_anchorType',
        'get_postAnchorPage',
        
        ]),
        // remarkContent: {
        //     get: function() {
        //         // while (this.remindUserIdList.length) {
        //         //     this.remindUserIdList.pop();
        //         // }
                
                
                
                
                
        //         return this.remark
        //     },
        //     set: function (value) {
        //         this.remark = value
        //     },
        // },
        getZanList () {
            const zl = this.zanList
            let userInfo = this.$global.getItem ('userInfo', true)
            if (!userInfo) {
                userInfo = this.$global.getItem ('userInfo') || {userId: '', userName: ''}
            }
            const id = userInfo.userId
            const name = userInfo.userName
            const hasSelf = zl.some (item => item.userId === id)
            if (this.isZan && !hasSelf) {
                zl.push ({
                    userId: id,
                    userName: name
                })
                return zl
            } else if (!this.isZan && hasSelf) {
                const noZanList = zl.filter (item => item.userId !== id)
                return noZanList;
            } else {
                return zl
            }
        },
        // 合并外部传参以及内部局部更新时需要的情况
        replyAllList () {
            const list = this.replyList || []
            const _list = this.selfReplyList || []
            const mergedReplyList = [...list, ..._list]

            mergedReplyList.forEach (item => {
                if (item.replyBeRemindUserList && item.replyBeRemindUserList.length > 0) {
                    
                    const replacedStringArray = []
                    let replaceStr = item.content
                    
                    if (replaceStr) {
                        item.replyBeRemindUserList.forEach(reminderUser => {
                        let showIndex = replaceStr.indexOf(reminderUser.userName)
                        
                        if (showIndex >=0) {
                            let currentReplaceStr = replaceStr.substring(0, showIndex + reminderUser.userName.length)
                            replacedStringArray.push(currentReplaceStr.replace("@" + reminderUser.userName, '<span class="reminderUser">@' + reminderUser.userName + '</span>'))
                            replaceStr = replaceStr.substring(showIndex + reminderUser.userName.length, replaceStr.length)
                        }
                    })
                    replacedStringArray.push(replaceStr)

                    item.content = replacedStringArray.join("")
                    }
                    
                }
            })
            return mergedReplyList
        },
        wordHeight () {
            const w = this.remark.length
            const input = this.$refs.inputPanel
            const inp_width = input?.getBoundingClientRect ()?.width || (this.$global.ua ().isTOUCH ? 240 : 1200)
            const step = Math.floor (inp_width / 15)
            const line = Math.floor (w / step) >= 4 ? 4 : Math.floor (w / 15)
            return 42 + line * 16
        }
    },
    
    methods: {
        ...mapActions([
            'set_postAnchorList',
            'set_anchorType', // 1 不传 2 传一些  3全部
            ]),
        // handleOnListenerInput (e) {
            // const inputValue = e.data
            // if (inputValue[inputValue.length - 1] === '@') {
            //     const {companyId, groupId, keyword} = this.$route.query
            //     this.$router.replace ({
            //         path: '/group/choose', query: {companyId, groupId, keyword,postsId:this.postsId}
            //     })
            // }
        // },
        reply (i) {
            const userInfo = this.$global.getItem ('userInfo')
            const id = userInfo.userId
            const isSelf = i.id === id
            this.replyId = i.replyId || 0
            this.replyItem = i
            if (!isSelf) {
                this.placeholder = `回复${i.fromUserName}`
            } else {
                this.placeholder = '说点什么...'
            }
            const inputContent = this.$refs.inputPanel
            if (inputContent) {
                inputContent.focus ()
            }
        },
        submitRemarkFn () {
            console.log("评论内容", this.remark)
            if (!this.remark || this.remark.trim().length == 0) {
                          this.$toast('帖子回复为空')
                          return
            }
            const obj = {
                postsId: this.postsId,
                replyId: this.replyId || 0,
                replyContent: this.remark,
                remindUserIdList: (this.get_anchorType && this.get_anchorType == 3) ? [] : this.remindUserIdList,
            }
            console.log("remindUserIdList", this.remindUserIdList)
            console.log("get_anchorType", this.get_anchorType)
            postApi.postReply (obj).then (res => {
                if (res.data) {
                    this.$notify ({message: '评论成功', type: 'success'})
                    this.remark = ''
                    this.$emit ('update')
                    // console.log (this.selfReplyList, res.data);
                    this.selfReplyList.push (res.data)
                    this.remindUserIdList = []
                }
            })
        },
        inputKeyEvent(e) {
            console.log("input value ", e.target.value)
            console.log("e.keycode:", this.lastInput)
            // this.$router.push ({
            //     path: '/post/choose',
            //     query: {
            //     groupId: this.groupId,
            //     postsId: this.postsId,
            //     from: 'reply',
            //     meta: { keepAlive: true },
            //     }
            // })
            if (this.remark.length > this.lastInput.length) {
                let currentInput = this.remark.substring(this.lastInput.length, this.remark.length)
                if (currentInput == '@') {
                    this.showChoose = true
                    this.activatedFlag = true
                    if (this.$refs.replyChoose) {
                        this.$refs.replyChoose.chooseList = []
                    }
                }
            }
            

            this.lastInput = e.target.value
            
        },
        gotoChoose() {
            this.showChoose = true
            this.activatedFlag = true
            if (this.$refs.replyChoose) {
                this.$refs.replyChoose.chooseList = []
            }
        },
        closeChoosePopup() {
            this.showChoose = false
        },
        blurRemarkFn () {
            if (!this.remark) {
                this.replyId = 0
                this.replyItem = null
                this.placeholder = '说点什么...'
            }
        },
        zanFn () {
            this.$emit ('zan')
        },
        startFn () {
            this.$emit ('star')
        },
        shareFn () {
            this.$emit ('share')
        },
    },
    
}
</script>

<style lang="less" scoped>
.replyPanel {
    background: #F6F7F8;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;
    
    .header {
        padding: 12px 0;
        position: relative;
        font-size: 14px;
        color: #2D2D2D;
    }
    
    .icon_img {
        width: 18.5px;
        height: 18.5px;
        padding-left: 14px;
        padding-right: 5px;
    }
    
    .replyItem {
        position: relative;
        padding: 5px 13px 0 13px;
        padding-bottom: 8px;
    }
    
    .name {
        font-size: 14px;
        color: #9B9B9B;
        line-height: 20px;
    }
    
    .date {
        font-size: 11px;
        color: #9B9B9B;
        line-height: 16px;
    }
    
    .content {
        font-size: 14px;
        color: #2D2D2D;
        line-height: 20px;
        width: 100%;
        word-break: break-all;
        word-wrap: break-word;
    }
    
    .contentWp {
        padding-bottom: 8px;
    }

    
}

.remarkPanel {
    height: 42px;
    border-radius: 40px;
    overflow: hidden;
    position: relative;
    padding: 14px 15px;
    padding-right: 17px;
    font-size: 14px;
    background: #F6F7F8;
    line-height: 16px;
    box-sizing: border-box;
    resize: none;
    border: none;
    width: 100%;
}

.iconInfo {
    width: 21px;
    height: 21px;
    margin-left: 14px;
    position: relative;
}

.icon_send {
    position: absolute;
    right: 15px;
    bottom: 13px;
    font-size: 18px;
}



</style>
