<template>
    <div class="f_flex f_a_s infoPanel">

        <van-overlay :show="showOverlay" @click="showOverlay = false">
            <img src="../../assets/_weixin_share_arrow.png" class="top-right-image" @click.stop>
        <div class="custom-content" @click.stop> <!-- click.stop 阻止冒泡 -->
           
            <div class="share_img"></div>
        </div>
        </van-overlay>
  
        <div class="f_s0" style="margin-right: 11px;">
            <div class="f_flex f_col f_a_c f_j_c">
                <div class="avatar">
                    <img style="width: 100%; height: 100%" :src="avatar" alt="">
                </div>
                <div v-if='infoComponent.urgentStatus === 2' class="status f_flex f_a_c f_j_c">紧急</div>
                <div class="waitpass subTitle" v-if='infoComponent.auditingStatus != 1'>
                    {{ authStatus[infoComponent.auditingStatus] }}
                </div>
                <div class="addOn" @click="showAddOnStatusChangeDialog">
                    <img :src="getAddOnImg(infoComponent.andonStatus, false, true)" style="width: 100%; height: 100%" alt="" />
                </div>
            </div>
        </div>
        
        <div class="f_g1 underline" style="padding-bottom: 14px;">
            <div class="f_flex f_a_e f_w">
                <div class="name">{{ infoComponent.postsUserName }}</div>
                <div class="part" :class="{textHidden: isMobile}">{{ getPostName }}</div>
                <div class="part_num theme_c" @click="goPostListpage(infoComponent.styleNo)">{{ infoComponent.styleNo }}</div>
            </div>
            <div class="f_flex f_a_c f_w" style="margin-top: 3px">
                <div class="tagItem" v-for='(item, index) in infoComponent.labelList' :key="index">#{{ item.labelName }}#</div>
            </div>
            <div class="content">
                {{ infoComponent.content }}
                <span v-if='!infoComponent.remindUserType || infoComponent.remindUserType!=3'>
          <span
              class="atNameItem theme_c"
              v-for='(item, index) in infoComponent.beRemindUserList'
              :key='index'>@{{ item.userName }}</span>
        </span>
                <span v-if='infoComponent.remindUserType && infoComponent.remindUserType==3' class="atNameItem theme_c">@所有人</span>
            </div>
            
            <div class="f_flex f_a_c f_w">
                <!-- <div v-for='(item, index) in infoComponent.postsImgUrls' v-viewer :key='index'>
                  <img class="imgItem" v-lazy="item" :src="item" alt="">
                  <van-image lazy-load width="79" height="79" class="imgItem img" :src="item" alt="" />
                </div> -->
                
                <!-- <viewer :images="imgList" :options="{url: 'data-src'}">
                  <img v-for="(item, index) in imgList" class="imgItem" v-lazy="item.thumbnail" :data-src="item.source" :src="item.thumbnail" :key="index">
                </viewer> -->
                
                <div v-viewer="options" class="images f_flex f_a_c f_w">
                    <template v-for="(image, index) in imgList">
                        <div :key='index' :data-src="image.source" class="imgItem">
                            <img
                                @click="handleOnSignItem"
                                :src="image.thumbnail"
                                class="img"
                                :key="index"
                                :data-big="image.source"
                                v-lazy="image.thumbnail"
                            >
                        </div>
                    </template>
                </div>
            </div>
            <!-- 文件列表 start-->
                


                <div class="file-list">
                    <div class="f_flex f_a_c item"  v-for='(item, index) in fileList' :key='index'>
                        <img slot='img' style="width: 10px; height: 18px" src="../../assets/attach_download.png" alt="">
                        
                    <a target="_blank" rel="noopener noreferrer" class="file-list-item-name" title="xxx.png" :href="item.uri">
                        {{item.originName}} 
                    </a>
                        
                    </div>
                </div>
                <!-- 文件列表 end-->
            <div style='margin-top: 5px' class="f_flex f_a_c f_j_sb" v-if='infoComponent.hasReadUserDesc'>
                <p class="subTitle f_flex f_w">
                    <!-- <span>{{infoComponent.hasReadUserList[0].userName}} 已读</span> -->
                    <span @click="goReadDetailListPage">{{ infoComponent.hasReadUserDesc }}</span>
                </p>
            </div>
            
            <div class="f_flex" v-if='infoComponent.locationAddress' style="margin-top: 7px" @click='goMap'>
                <img style="width: 10px; height: 14px; margin-right: 4px; padding-top: 4px"
                     src="../../assets/icon_location.png"
                     alt="">
                <p class="subTitle">{{ infoComponent.locationAddress }}</p>
            </div>
            
            <div class="f_flex f_a_c" style="margin-top: 7px">
                <p class="subTitle">{{ infoComponent.publicTime }}</p>
                <p v-if='canDelete' @click='deleteFn' class="subTitle theme_c" style="margin-left: 13px;">删除</p>
                <p class="subTitle theme_c" style="margin-left: 13px;" @click="sendParamsRedirect">复制</p>
                <p class="subTitle theme_c" style="margin-left: 13px;" @click="sendParamsForUpdate">修改</p>
                <p class="subTitle theme_c" style="margin-left: 13px;" @click="shareToWeixin" v-if="showShare">分享</p>
            </div>
            
            <div style="margin-top: 15px" v-if='infoComponent.auditingStatus == 1'>
                <reply
                    :isStar='isStar'
                    :isZan='isZan'
                    :showStar='showStar'
                    :postsId="infoComponent.postsId"
                    :groupId="infoComponent.groupId"
                    @zan='zanFn'
                    @star='starFn'
                    @update='updatePostItemFn'
                    :zanList='infoComponent.hasLikedUserList'
                    :replyList='infoComponent.postsReplyRecordList'>
                </reply>
            </div>
        </div>

        <van-overlay :show="showAddOnStatusDialog" >
            <div class="wrapper">
              
                
                <div class="f_col addon_panel">
                    <div class="addon_panel_head">
                        <p>修改跟进状态</p>
                    </div>
                      
                    <div class="f_flex f_j_s addon_panel_cell">
                        <p class="addon_panel_title">当前状态</p>
                        <p>{{infoComponent.andonStatusDesc}}</p>
                    </div>

                    <div class="f_flex f_j_s addon_panel_cell ">
                        <p class="addon_panel_title addon_panel_cell_button">选择状态</p>
                        <div class="f_flex f_g1">
                            <div class="add_on_button" v-if="infoComponent.andonStatus > 0" @click="changeAddOnStatus(infoComponent.addOnStatus)">
                                <img class="addon_panel_img" :src="getAddOnImg(infoComponent.andonStatus, false, false)"  alt="" />
                                <p class="addon_panel_img_remark">{{this.infoComponent.andonStatusDesc}}</p>
                            </div>
                            <div class="add_on_button" @click="changeAddOnStatus(getNextAddOnStatus())">
                                <img class="addon_panel_img" :src="getAddOnImg(getNextAddOnStatus(), true, false)"  alt="" />
                                <p class="addon_panel_img_remark">{{addOnStatuxNextDesc}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="f_flex f_j_s addon_panel_cell addon_panel_input_cell">
                        <p class="addon_panel_title">添加备注</p>
                        <div class="cell_button_container">
                            <img key='n' class="iconInfo" src="../../assets/at-sign-white.png" alt="" @click="gotoChoose">
                            
                            <input class="addon_panel_input" placeholder="可追加@" 
                            @keyup="inputKeyEvent"
                             v-model='addOnRemark'
                             ref='addOnInputPanel' />
                             
                        </div>
                        
                    </div>

                    <div class="f_flex f_j_s addon_panel_button_cell f_s0">
                        <button class="addon_cancel" @click="showAddOnStatusDialog = false">取消</button>
                        <button class="addon_submit" @click="submitChangeAddOn">提交</button>   
                    </div>    
            </div>
            
            </div>
            
        </van-overlay>

        <!-- <van-dialog v-model="showAddOnStatusDialog" title="修改跟进状态" theme="round-button" show-cancel-button>
            
            
        </van-dialog> -->

        <van-popup
            get-container="#app"
            v-model="showChoose"
            position="bottom"
            :style="{ height: '90%' }"
            >
            <choose ref="addOnChoose" :groupId="infoComponent.groupId" :postsId="infoComponent.postsId" fromPage="addOn" @closeChoose="closeChoosePopup"/>
        </van-popup>

    </div>
</template>

<script>
import reply from './reply.vue'
import postApi from '@/api/post'
import {mapActions, mapGetters} from 'vuex'
// import {Image as vanImage} from 'vant'
import addonStatus0 from '@/assets/addonStatus0.png'
import addonStatus1 from '@/assets/addonStatus1.png'
import addonStatus2 from '@/assets/addonStatus2.png'
import addonStatus3 from '@/assets/addonStatus3.png'
import addonStatusEmpty1 from '@/assets/addonStatusEmpty1.png'
import addonStatusEmpty2 from '@/assets/addonStatusEmpty2.png'
import addonStatusEmpty3 from '@/assets/addonStatusEmpty3.png'

import choose from './choose.vue'

export default {
    data: () => ({
        showOverlay: false,
        isZan: false,
        isStar: false,
        canDelete: false,
        authStatus: ['', '已审核', '审核拒绝', '审核中'],
        placeholder: '说点什么...',
        isMobile: false,
        options: {
            url: 'data-big'
        },
        showAddOnStatusDialog: false,
        addOnStatus: ['', '需跟进', '跟进中', '已完结'],
        addonStatusImage: [addonStatus0, addonStatus1, addonStatus2, addonStatus3],
        addonStatusEmptyImage: ["", addonStatusEmpty1, addonStatusEmpty2, addonStatusEmpty3],
        currentAddOnStatus: -1,
        addOnRemark: '',
        remindUserIdList:[],
        infoLocal: null,
        showChoose: false,
        lastInput: '',
        showShare: true
    }),
    components: {
        reply,
        choose,
        // 'van-image': vanImage
    },
    watch: {
        get_postAnchorListMap: function() {
            console.log("info panel get_postAnchorPage", this.get_postAnchorPage)
            let postAnchorListMap = this.get_postAnchorListMap
            
            if (this.get_postAnchorPage == 'addOn' && postAnchorListMap && postAnchorListMap.get) {
                
                let anchorList = postAnchorListMap.get(this.infoComponent.postsId)
                
                if (anchorList && anchorList.length > 0) {
                    const inputContent = this.$refs.addOnInputPanel
                    if (inputContent) {
                        inputContent.focus ()
                    }
                    anchorList.forEach ((item, index) => {
                        if (index == 0) {
                            this.addOnRemark += item.userName
                        } else {
                            this.addOnRemark += "@" + item.userName
                        }
                        
                        this.remindUserIdList.push (item.userId) 
                    })

                    console.log("info panel this.addOnRemark", this.addOnRemark)
                }
                
            }
        },
        
    },
    computed: {
        infoComponent() {
            if (this.infoLocal) {
                
                return this.infoLocal
            } else {
                return this.info
            }
        },
        ...mapGetters (['get_labelList', 'get_styleNum', 'get_postAnchorListMap', 'get_postAnchorPage']),
        content () {
            let c = this.infoComponent.content
            const remind = this.infoComponent.beRemindUserList || []
            for (let i = 0; i < remind.length; i++) {
                c = c.replace (/(\$\{\})/, () => {
                    return `<span class="atNameItem theme_c"> @${remind[i].userName}</span>`
                })
            }
            return c
        },
        addOnStatuxNextDesc() {
            return this.addOnStatus[this.getNextAddOnStatus()]
        },
        avatar () {
            return this.infoComponent.postsUserAvatar.match (/^http/) ? this.infoComponent.postsUserAvatar : `${this.$global.hostUrl ()}${this.infoComponent.postsUserAvatar}`
        },
        getPostName () {
            const source = this.infoComponent.postsSource
            const name = this.infoComponent.groupName
            if (source) {
                return source == 1 ? name : (source == 2 ? '广场' : '私密本')
            }
            return name
        },
        imgList () {
            const imgList = this.infoComponent.postsImgUrls
            const arr = []
            for (let i = 0; i < imgList.length; i++) {
                const img = imgList[i].replace (/_small\.(png|jpg|jpeg|gif)$/, '_big.$1')
                arr.push ({
                    thumbnail: imgList[i],
                    source: img
                })
            }
            return arr
        },
        fileList() {
            const fileList = this.infoComponent.attachedUrls
            return fileList
        }
    },
    created () {
        this.isStar = this.infoComponent.hasAttention === 1
        this.isZan = this.infoComponent.hasLiked === 1
        this.canDelete = this.infoComponent.canDelete === 1
        this.isMobile = this.$global.ua ().isTOUCH
    },
    props: {
        info: {
            type: Object,
            default: () => ({})
        },
        groupId: {
            type: [Number, String],
            default: 0
        },
        companyId: {
            type: [Number, String],
            default: 0
        },
        index: {
            type: [Number, String],
            default: 0
        },
        showStar: {
            type: Boolean,
            default: true
        },
        // 点击款号事件
        keywordEvent: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        submitChangeAddOn() {
            if (!this.addOnRemark || this.addOnRemark.trim().length == 0) {
                this.remindUserIdList = []
            }
            postApi.addOnOpen ({
                postsId: this.infoComponent.postsId,
                andonStatus: this.currentAddOnStatus > -1 ? this.currentAddOnStatus : this.infoComponent.andonStatus,
                content: this.addOnRemark,
                remindUserIdList: this.remindUserIdList
            }).then (res => {
                if (res.data) {
                    this.showAddOnStatusDialog = false
                    // this.updatePostItemFn()

                    postApi.postDetail({
                        postsId: this.infoComponent.postsId,
                    }).then( detailRes => {
                        this.infoLocal = detailRes.data.postsInfo
                        console.log(detailRes.data)
                    })
                }
            })
        },
        inputKeyEvent(e) {
            console.log("show popup value ", e.target.value)

            if (this.addOnRemark.length > this.lastInput.length) {
                let currentInput = this.addOnRemark.substring(this.lastInput.length, this.addOnRemark.length)
                if (currentInput == '@') {
                    this.showChoose = true
                    
                    if (this.$refs.addOnChoose) {
                        this.$refs.addOnChoose.chooseList = []
                    }
                }
            }
            

            this.lastInput = e.target.value
            // this.$router.push ({
            //     path: '/post/choose',
            //     query: {
            //     groupId: this.infoComponent.groupId,
            //     postsId: this.infoComponent.postsId,
            //     from: 'addOn',
            //     meta: { keepAlive: true },
            //     }
            // })
           
        },
         gotoChoose() {
            this.showChoose = true
                    
            if (this.$refs.addOnChoose) {
                this.$refs.addOnChoose.chooseList = []
            }
        },
        closeChoosePopup() {
            this.showChoose = false
        },
        getNextAddOnStatus() {
            return this.infoComponent.andonStatus == 3 ? 1: this.infoComponent.andonStatus + 1;
        },
        changeAddOnStatus(status) {
            this.currentAddOnStatus = status;
        },
        getAddOnImg(status, defaultEmpty, indexPage) {
            if (indexPage) {
                return this.addonStatusImage[status];
            }

            if (this.currentAddOnStatus > -1) {
                return this.currentAddOnStatus == status ? this.addonStatusImage[status] : this.addonStatusEmptyImage[status]
            } else {
                return !defaultEmpty ?  this.addonStatusImage[status] : this.addonStatusEmptyImage[status]
            }
        },
        // eslint-disable-next-line no-undef
        ...mapActions (['set_styleNum', 'set_labelList', 'set_updateContent']),
        sendParamsRedirect () {
            const companyId = JSON.parse (window.localStorage.getItem ('companyInfo')).companyId
            const {labelList, postsSource, styleId, groupId} = this.info
            let {styleNo} = this.info
            if (styleNo.includes (' ')) {
                const strArr = styleNo.split (' ')
                styleNo = strArr[strArr.length - 1]
            }
            if (styleNo) {
                this.set_styleNum ({styleNo: styleNo ? styleNo : '', styleId: styleId ? styleId : 0})
            }
            if (labelList) {
                this.set_labelList (labelList ? labelList : [])
            }
            
            setTimeout (() => {
                this.$router.push ({
                    path: '/post',
                    query: {
                        postsSource,
                        companyId,
                        groupId,
                        from:'copy'
                    }
                })
            }, 100)
            
            
        },

        sendParamsForUpdate () {
            const companyId = JSON.parse (window.localStorage.getItem ('companyInfo')).companyId
            const {labelList, postsSource, styleId, groupId, postsId, content} = this.info
            console.log('content', content)
            let {styleNo} = this.info
            if (styleNo.includes (' ')) {
                const strArr = styleNo.split (' ')
                styleNo = strArr[strArr.length - 1]
            }
            this.set_styleNum ({styleNo: styleNo ? styleNo : '', styleId: styleId ? styleId : 0})
            this.set_labelList (labelList ? labelList : [])
            this.set_updateContent(content ? content : '')
            setTimeout (() => {
                this.$router.push ({
                    path: '/post',
                    query: {
                        postsSource,
                        companyId,
                        groupId,
                        postsId,
                        from:'update'
                    }
                })
            }, 100)
            
            
        },
        handleOnSignItem () {
            const {postsId} = this.info
            postApi.maskImageRead ({postsId}).then (() => {
                // postApi.getRemindListByPostsId({postsId}).then(res=>{
                //   console.log(res)
                //   const userName = this.$global.getItem('userInfo', ).userName
                //   console.log(userName)
                //   const userList = res.data.readDetailUserList.map(item=>item.userName)
                //   userList.includes(1)
                //   console.log(userList)
                // })
            })
        },
        // 点赞
        zanFn () {
            postApi.postLike ({
                postsId: this.infoComponent.postsId,
                type: this.isZan ? 2 : 1
            }).then (res => {
                if (res.data) {
                    this.$toast (res.data.msg)
                    this.isZan = !this.isZan
                    // this.updatePostItemFn()
                }
            })
        },
        goPostListpage (title) {
            if (!this.keywordEvent) {
                return
            }
            const companyInfo = this.$global.getItem ('companyInfo', true)
            const companyId = this.$route.query.companyId || companyInfo.companyId
            this.$router.push (`/postList?styleNoId=${this.infoComponent.styleId}&companyId=${companyId}&title=${title}`)
        },
        // previewImagesFn(index) {
        //   ImagePreview({images: this.infoComponent.postsImgUrls, startPosition: index})
        // },
        // 关注
        starFn () {
            const obj = {
                postsId: this.infoComponent.postsId,
                type: this.isStar ? 2 : 1
            }
            const companyInfo = this.$global.getItem ('companyInfo', true)
            if (companyInfo) {
                obj.companyId = companyInfo.companyId
            }
            postApi.postAttention (obj).then (res => {
                if (res.data) {
                    this.$toast (res.data.msg)
                    this.isStar = !this.isStar
                    // this.updatePostItemFn()
                }
            })
        },
        replyItemFn (e) {
            const {isSelf, item} = e
            if (!isSelf) {
                this.placeholder = `回复${item.name}`
            }
        },
        updatePostItemFn () {
            this.$emit ('update', {index: this.index, postsId: this.infoComponent.postsId})
        },
        goReadDetailListPage () {
            if (this.infoComponent.moreHasReadUserStatus == 2) {
                return;
            }
            const {postsId} = this.info
            this.$router.push ({
                path: '/hasRead',
                query: {
                    postsId: postsId
                }
            })
        },
        goMap () {
            this.$router.push (`/map?lng=${this.infoComponent.longitude}&lat=${this.infoComponent.latitude}&flag=${false}`)
        },
        deleteFn () {
            this.$dialog.confirm ({
                message: '确定要删除帖子吗？'
            }).then (() => {
                postApi.postDelete ({
                    postsId: this.infoComponent.postsId
                }).then (res => {
                    if (res.data) {
                        this.$toast (res.data.msg)
                        this.$emit ('delete', {index: this.index})
                    }
                })
            }).catch (() => {
            })
        },
        goGroupInfo () {
            const status = this.infoComponent.auditingStatus
            if (status == 1 && this.groupId) {
                const id = this.groupId
                const companyId = this.companyId
                this.$router.push (`/group/info?groupId=${id}&companyId=${companyId}`)
            }
        },
        
        shareToWeixin() {
            console.log("shareToWeixin", this.info)
            var shareImageUrl = ""
            //const companyId = JSON.parse (window.localStorage.getItem ('companyInfo')).companyId
            const {content, groupName, postsImgUrls, styleNo, postsId} = this.info

            const link = `https://app.ifeitie.com/#/detail?postsId=${postsId}`;

            if (postsImgUrls && postsImgUrls.length > 0) {
                shareImageUrl = postsImgUrls[0]
            }

            this.showOverlay = true
            var that = this
            this.$wx.ready(function() {
                    // 配置“分享给朋友”的信息
                    that.$wx.updateAppMessageShareData({ 
                        title: groupName + styleNo, // 分享标题
                        desc: content, // 分享描述
                        link: link, // 分享链接，默认为当前页面链接
                        imgUrl: shareImageUrl, // 分享图标
                        success: function () {
                        // 用户点击了分享后执行的回调函数
                        console.log('分享给朋友成功');
                        }
                    });
                })
            

            
            },

            
        downloadFile(file) {
            postApi.fileDownload ({
                    fileName: file.uri,
                    responseType: 'blob'
                }).then (res => {
                    let blob = new Blob([res], {type: "image/png"});
                    if (window.navigator.msSaveOrOpenBlob) {
                        console.log("msSaveBlob")
                        navigator.msSaveBlob(blob, file.originName);
                    } else {
                        let link = document.createElement("a");
                        let evt = document.createEvent("HTMLEvents");
                        evt.initEvent("click", false, false);
                        link.href = URL.createObjectURL(blob); 
                        link.download = file.originName;
                        link.style.display = "none";
                        document.body.appendChild(link);
                        link.click();
                        window.URL.revokeObjectURL(link.href);
                    }
                })
        },
        showAddOnStatusChangeDialog() {
            this.showAddOnStatusDialog = true;
            this.currentAddOnStatus = -1
            this.addOnRemark = ''
            this.remindUserIdList = []
        }
    },
    mounted () {
        // console.log (this.info)
    

        
    
    }
}
</script>

<style lang="less" scoped>
.avatar {
    width: 44px;
    height: 44px;
    border-radius: 40px;
    overflow: hidden;
}
.addOn {
    width: 30px;
    height: 30px;
    margin-top: 10px;
}
.subTitle {
    font-size: 13px;
    line-height: 18px;
    color: #9B9B9B;
}

.iconRirhtArrow {
    font-size: 16px;
    color: #999;
    margin-left: 4px;
    line-height: 18px;
}

.infoPanel {
    width: 100%;
    padding-top: 18px;
    padding-bottom: 14px;
    
    .waitpass {
        margin-top: 6px;
    }
    
    .status {
        background: #EA574C;
        font-size: 12px;
        padding: 2px 6px;
        border-radius: 4px;
        line-height: 13px;
        color: #fff;
        margin-top: 8px;
    }
    
    .name {
        font-size: 16px;
        color: #2D2D2D;
        margin-right: 15px;
        font-weight: 400;
        line-height: 22px;
        
    }
    
    .part {
        font-size: 13px;
        color: #4A4A4A;
        margin-right: 15px;
        font-weight: 400;
        line-height: 18px;
    }
    
    .textHidden {
        max-width: 120px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    
    .part_num {
        font-size: 13px;
        line-height: 18px;
    }
    
    .tagItem {
        font-size: 14px;
        margin-right: 6px;
        line-height: 20px;
        color: #999;
    }
    
    .content {
        font-size: 15px;
        color: #2D2D2D;
        line-height: 22px;
        margin: 4px 0;
        word-break: break-all;
        word-wrap: break-word;
        white-space: pre-wrap;
    }
    
    .atNameItem {
        padding-right: 10px;
        font-size: 15px;
    }
    
    .imgItem {
        width: 79px;
        height: 79px;
        margin-right: 8px;
        margin-bottom: 6px;
        background: #f1f1f1;
    }
    
    .file-list-item-name {
        font-size: 13px;
        font-weight: 400;
        color: #4A90E2;
        line-height: 20px;
        padding: 0 0 0 5px;
    }

    .addon_panel {
        margin-top: 10px;
        font-size: 18px;
        align-items: center;
        justify-content: center;
        width: 345px;
        height: 355px;
        background-color: #fff;
        color: #2D2D2D;
        font-size: 15px;
        border-radius: 10px;
    }

    .addon_panel_head {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        width: 100%;
        margin: 20px 0px 30px 10px;
        display: flex;
        justify-content: center; /* 水平居中 */
    }
    .addon_panel_head p {
        font-size: 16px;
    }

    .addon_panel_cell {
        margin: 20px 0px 20px 20px;
    }

    .addon_panel_button_cell {
        margin: 20px 0px 20px 20px;
        justify-content: center; /* 水平居中 */
    }

    .addon_panel_cell_button {
        height: 80px;
        line-height: 80px;
    }

    .addon_panel_title {
        margin-right: 20px;
        font-size: 15px;
    }

    .add_on_button {
        display: flex;
        flex-direction: column;
        justify-content: center; /* 水平居中 */
        align-items: center;  
        margin-right: 60px;
    }

    .addon_panel_img {
        width: 30px;
        height: 30px;
    }

    .addon_panel_img_remark {
       font-size: 12px;
       margin: 0;
    }

    .cell_button_container{
        display: flex;
        position: relative;
        width: 217px;
        border: 1px solid #C3C3C3;
        line-height: 36px;
        position: relative;
        
        display: flex;
        
    }
    .cell_button_container input{
        position: relative;
        width: 120px;
        height: 34px;
        border-style:none;
        padding-left:2px;
        
    }
    .cell_button_container_img {
        position: absolute;
        top: 7px;
        right: 0;
        background-color: #fff;
        border: none;   // 去掉边框
        outline: none;  // 去掉点击按钮后的边框
     }

    .addon_panel_input_cell {
         height: 36px;
        line-height: 36px;
    }
    

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .addon_submit {
        width: 130px;
        height: 44px;
        background: #4F92E6;
        border-radius: 24px;   
        border: 0px; 
        color: #ffffff;
        margin-top: 30px;
        margin-left:20px;
    }
    .addon_cancel {
        width: 130px;
        height: 44px;
        border-radius: 24px;
        border: 1px solid #4F92E6;
        color: #4F92E6;
        background: #ffffff;
        margin-top: 30px;
    }
    .input-group-prepend {
        position: relative;
        z-index: 2;
        
    }
    .btn-outline-secondary {
        display: inline-block;
        color: #6c757d;
        border-color: #6c757d;
        width: 71px;
        height: 36px;
        display: inline-block;
        font-weight: 400;
        color: #212529;
        text-align: center;
        line-height: 36px;
        background-color: #c3c3c3;
        border: 1px solid transparent;
        box-sizing: border-box;
    }

    
.iconInfo {
    width: 21px;
    height: 21px;
    
    position: relative;
    margin-top: 6px;     
}

#__for_share {
    position: fixed;
    top: 0;
    left: 0;
    background: black url("../../assets/_weixin_share_arrow.png") right top no-repeat;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    display: none; /* 默认不显示 */
    justify-content: center;
    align-items: center;
  }

  .custom-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  width: 320px; /* Adjust according to your needs */
  height: 200px; /* Adjust according to your needs */
}

.share_img {
  background: url('../../assets/_weixin_share_info.png') no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.top-right-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: auto; /* 保持图片比例 */
}
    
}


</style>
